export const environment = {
  production: false,
  eventsBaseUrl: 'https://tj65zc9242.execute-api.eu-west-1.amazonaws.com/dev',
  photosBaseUrl: 'https://j5h2t8uxyd.execute-api.eu-west-1.amazonaws.com/dev',
  usersBaseUrl: 'https://jip3dzn387.execute-api.eu-west-1.amazonaws.com/dev',
  notificationsBaseUrl: 'https://7izfvzom7i.execute-api.eu-west-1.amazonaws.com/dev',
  paymentsBaseUrl: 'https://ngwm0nzoyh.execute-api.eu-west-1.amazonaws.com/dev',
  eventPhotoPrefix: 'EVNT',
  profilePhotoPrefix: 'PRFL',
  apiKey: 'e4d3c2ba1d3c6f5e4f52ba16',
  sentryDsn:'https://e7a1692089d95d3018f23a4c6f2bb59d@o4505632836354048.ingest.sentry.io/4505634988032000',
  tracesSampleRate: 0.5,
  replaysSessionSampleRate: 0.5,
  replaysOnErrorSampleRate: 1.0,
};
