import getSequentialStringOptimalLength from "./get-sequential-string-optimal-length";
/**
 * This method helps to calculate the actual index used to generate the sequential string
 *
 * @param serialNumber The running serial number
 * @param charsetLength The length of the character set that would be used to generate the sequential string
 */
export default ((serialNumber, charsetLength) => {
  const length = getSequentialStringOptimalLength(serialNumber, charsetLength);
  let secondaryIndexDifference = 0;
  for (let stringSequenceLength = length - 1; stringSequenceLength > 0; stringSequenceLength -= 1) {
    secondaryIndexDifference += charsetLength ** stringSequenceLength;
  }
  return {
    index: serialNumber - secondaryIndexDifference,
    length
  };
});